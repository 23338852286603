import apiConfig from "./apiConfig";
import axios from "axios";
// import GlobalData from "./GlobalData";

export default class ApiRequest {

  static set = async (endpoint, method, body, headers) => {
    let response = await fetch(endpoint.includes('http') ? endpoint : apiConfig.base_url + endpoint, {
      method: method,
      headers: headers ? headers :
        {
          'Content-Type': 'application/json',
          Authorization : localStorage.getItem("token") ? `bearer ${localStorage.getItem("token")}` : null,
          Accept: 'application/json',
        },
      body: JSON.stringify(body)
    });

    if (response.ok) {
      return response.json()
    } else {
      let error = await response.json()

      console.log(error)

      throw error
    }
  }
}
