import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import banner from "../assets/oph-coming-soon.jpg";
import Modal from "react-bootstrap/Modal";
import styles from "../values/styles";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import React, {useEffect, useState} from "react";
import {
    AiOutlinePlayCircle, BsPlayCircleFill,
    FaChalkboardTeacher,
    FiInstagram,
    FiYoutube, GiPresent,
    HiOutlineDesktopComputer,
    IoDocumentTextOutline, IoMdTrophy
} from "react-icons/all";
import {Link} from "react-router-dom";
import Header from "./Header";
import User from "../models/User";
import Spinner from "react-bootstrap/Spinner";
import Swal from 'sweetalert2'
import {useHistory} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"
import {Carousel} from "react-responsive-carousel";

import banner1 from "../assets/banners/7.jpg"
import banner2 from "../assets/banners/8.jpg"
import banner3 from "../assets/banners/9.jpg"
import banner4 from "../assets/banners/1920x900 voh live 202110.jpg"
import banner5 from "../assets/banners/Homepage slide 2.jpg"
import ReactPlayer from "react-player";
import {Card} from "react-bootstrap"

const banners = [
    {
        src: banner4,
        url: null
    },
    {
        src: banner5,
        url: "https://bit.ly/sgutalkshowvoh"
    },
    {
        src: banner1,
        url: "https://bit.ly/accountingminicompetition2021"
    },
    {
        src: banner2,
        url: "https://sgu-open-house.intellivent.id/eflyer/SEECOMP.pdf"
    },
    {
        src: banner3,
        url: "https://sgu.ac.id/news-casting-competition/"
    },
]

export default function LandingPage(props) {

    const history = useHistory();
    const userModel = new User();
    const [loginCreds, setLoginCreds] = useState({});
    const [registerCreds, setRegisterCreds] = useState({});
    const [isLoginShown, setLoginShown] = useState(false)
    const [isRegisterShown, setRegisterShown] = useState(false)
    const [errors, setErrors] = useState({})
    const [isLoading, setLoading] = useState(false)

    const [isPlayingVideo, setIsPlayingVideo] = useState(false)

    const [competitionModal, setCompetitionModal] = useState(false)
    const [doorPrizeModal, setDoorPrizeModal] = useState(false)

    useEffect(() => {

        // setTimeout(()=>{
        //     setIsPlayingVideo(true)
        // }, 5000)

        if (props.match.url === '/login' && !localStorage.getItem('token')) {
            Swal.fire({
                title: 'Please login before entering SGU virtual open house.',
                icon: 'info',
                confirmButtonText: 'OK'
            }).then((result) => {
                setLoginShown(true)
            })
        }
    }, [])

    useEffect(() => {
        if (!isLoginShown || !isRegisterShown) {
            setLoginCreds({})
            setRegisterCreds({})
            setErrors({})
            setLoading(false)
        }
    }, [isLoginShown, isRegisterShown])

    const attemptLogin = async () => {
        const errorsTemp = {}

        if (!loginCreds.email) {
            errorsTemp.email = 'Please fill out this field.'
        }

        if (!loginCreds.password) {
            errorsTemp.password = 'Please fill out this field.'
        }

        if (Object.keys(errorsTemp).length === 0) {
            setLoading(true)
            const {email, password} = loginCreds;

            try {
                const response = await userModel.login({
                    email,
                    password
                })

                console.log("logindata", response)

                localStorage.setItem('token', response.token)
                localStorage.setItem('gender', response.gender)
                localStorage.setItem('fullname', response.fullname)

                history.push('/expo')

                console.log(response)

                setLoading(false)
            } catch (e) {
                if (e.error_message === 'USERNAME_NOT_FOUND' || e.code === 'ADMIN_PASSWORD_WRONG') {
                    errorsTemp.form = 'Invalid Credential.'
                } else {
                    errorsTemp.form = 'An error occurred.'
                }

                setLoading(false)
            }
        }

        setErrors(errorsTemp)
    }

    const attemptRegister = async (e) => {
        const errorsTemp = {}

        if (!registerCreds.email) {
            errorsTemp.email = 'Please fill out this field.'
        }

        if (!registerCreds.mobile_phone) {
            errorsTemp.mobile_phone = 'Please fill out this field.'
        }

        if (!registerCreds.fullname) {
            errorsTemp.fullname = 'Please fill out this field.'
        }

        if (!registerCreds.gender) {
            errorsTemp.gender = 'Please fill out this field.'
        }

        if (registerCreds.password !== registerCreds.confirm_password) {
            errorsTemp.confirm_password = 'Password and confirm password does not match.'
        }

        if (!registerCreds.password) {
            errorsTemp.password = 'Please fill out this field.'
        }

        if (!registerCreds.confirm_password) {
            errorsTemp.confirm_password = 'Please fill out this field.'
        }

        if (!registerCreds.school_name) {
            errorsTemp.school_name = 'Please fill out this field.'
        }

        if (!registerCreds.class) {
            errorsTemp.class = 'Please select an item in the list.'
        }

        if (!registerCreds.program) {
            errorsTemp.program = 'Please select an item in the list.'
        }

        if (Object.keys(errorsTemp).length === 0) {
            setLoading(true)

            try {
                const {email, mobile_phone, fullname, password, school_name, program, gender} = registerCreds;

                const response = await userModel.register({
                    email,
                    mobile_phone,
                    fullname,
                    password,
                    school_name,
                    class: registerCreds.class,
                    program,
                    gender
                })

                Swal.fire({
                    title: 'You are successfully registered!',
                    text: 'Please login to access virtual open house.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    setRegisterShown(false)
                    setLoginShown(true)
                })
            } catch (e) {
                console.log(e)
                if (e.code === 'DUPLICATE_USERNAME') {
                    errorsTemp.email = 'Email is already registered.'
                } else {
                    errorsTemp.form = 'An error occurred.'
                }
            }
        }

        setLoading(false)
        setErrors(errorsTemp)
    }

    const joinExpo = () => {
        // if (localStorage.getItem('token')) {
            history.push('/expo')
        // } else {
        //     setLoginShown(true)
        // }
    }

    return <>

        <Header
            loginButton={true}
            joinExpo={joinExpo}/>

        {/*<Row style={{*/}
        {/*    marginTop: 70*/}
        {/*}}*/}
        {/*>*/}
        {/*    <Col md={12}>*/}
        {/*        <img*/}
        {/*            style={{*/}
        {/*                width: "100%",*/}
        {/*                objectFit: "contain"*/}
        {/*            }}*/}
        {/*            src={banner}/>*/}
        {/*    </Col>*/}
        {/*</Row>*/}

        <Row style={{
            marginTop: 70
        }}
        >
            <Col md={12}>
                <Carousel
                    onChange={() => {
                        setIsPlayingVideo(false)
                    }}
                    infiniteLoop showThumbs={false} showStatus={false}>
                    <div style={{
                        width: window.innerWidth,
                        height: window.innerWidth * 9 / 16 * 0.83,
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "black"
                    }}>
                        <ReactPlayer
                            // muted={!isPlayingVideo}
                            playing={isPlayingVideo}
                            width='100%'
                            height={window.innerWidth * 9 / 16 * 0.83}
                            url={"https://nos.jkt-1.neo.id/pustaka-bergerak/temp/main.mp4"}
                        />
                        {
                            !isPlayingVideo &&
                            <div
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: window.innerWidth * 9 / 16 * 0.83,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    backgroundColor: "#25252580",
                                    flexDirection: "column",
                                }}
                                onClick={() => {
                                    setIsPlayingVideo(true)
                                }}
                            >
                                <BsPlayCircleFill
                                    size={64}
                                    color={"white"}
                                />
                                <div style={{
                                    color: "white",
                                    fontSize: 36,
                                    marginTop: 12
                                }}>
                                    Welcome to SGU Virtual Expo 2021
                                </div>
                            </div>
                        }
                    </div>

                    {
                        banners.map(banner => (
                            <a
                                style={{
                                    display: " block"
                                }}
                                href={banner.url ? banner.url : null} target={"_blank"}>
                                <img
                                    src={banner.src} style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'fill',
                                    borderRadius: 5
                                }}/>
                            </a>
                        ))
                    }
                </Carousel>
            </Col>
        </Row>

        <Container>
            <Row style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: '15px',
            }}
            >
                <Col md={4} xs={6} xl={3} style={{marginBottom: 20}}>
                    <div
                        onClick={joinExpo}
                        style={{
                            boxShadow: "1px 1px 3px 1px #9E9E9E",
                            borderRadius: 5,
                            paddingTop: '10px',
                            paddingBottom: '5px',
                            width: '100%',
                            cursor: 'pointer'
                        }}>

                        <Container>
                            <Row style={{display: 'flex', justifyContent: 'center'}}>
                                <HiOutlineDesktopComputer size={25} style={{marginBottom: 5}}
                                                          color={'#32439e'}/>
                            </Row>
                            <Row style={{
                                ...styles.normalText,
                                fontWeight: '500',
                                fontSize: '0.9em',
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#333333'
                            }}>
                                Join Expo
                            </Row>
                        </Container>
                    </div>
                </Col>

                <Col md={4} xs={6} xl={3} style={{marginBottom: 20}}>
                    <div style={{
                        boxShadow: "1px 1px 3px 1px #9E9E9E",
                        borderRadius: 5,
                        paddingTop: '10px',
                        paddingBottom: '5px',
                        width: '100%'
                    }}>
                        <a href={"https://forms.gle/XVgS5ZtYLkY6suQj6 "}
                           style={{
                               textDecorationLine: 'none',
                               textAlign: "center"
                           }}>
                            <Container>
                                <Row style={{display: 'flex', justifyContent: 'center'}}>
                                    <FaChalkboardTeacher size={25} style={{marginBottom: 5}} color={'#32439e'}/>
                                </Row>
                                <Row style={{
                                    ...styles.normalText,
                                    fontWeight: '500',
                                    fontSize: '0.9em',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    color: '#333333'
                                }}>
                                    Meet the Lecturers
                                </Row>
                            </Container>
                        </a>
                    </div>
                </Col>

                <Col md={4} xs={6} xl={3} style={{marginBottom: 20}}>
                    <div style={{
                        boxShadow: "1px 1px 3px 1px #9E9E9E",
                        borderRadius: 5,
                        paddingTop: '10px',
                        paddingBottom: '5px',
                        width: '100%'
                    }}>
                        <a href={"https://entrancetest.sgu.ac.id/"}
                           target={'_blank'}
                           style={{
                               textDecorationLine: 'none',
                               textAlign: "center"
                           }}>
                            <Container>
                                <Row style={{display: 'flex', justifyContent: 'center'}}>
                                    <IoDocumentTextOutline size={25} style={{marginBottom: 5}} color={'#32439e'}/>
                                    {/*<img*/}
                                    {/*    style={{*/}
                                    {/*        height: "2.5em",*/}
                                    {/*        objectFit: "contain",*/}
                                    {/*        marginBottom: '5px',*/}
                                    {/*        fontWeight: 'bold'*/}
                                    {/*    }}*/}
                                    {/*    src={require("../../asset/icc_pasar.png")}/>*/}
                                </Row>
                                <Row style={{
                                    ...styles.normalText,
                                    fontWeight: '500',
                                    fontSize: '0.9em',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    color: '#333333'
                                }}>
                                    Entrance Test
                                </Row>
                            </Container>
                        </a>
                    </div>
                </Col>

                <Col md={4} xs={6} xl={3} style={{marginBottom: 20}}>
                    <div style={{
                        boxShadow: "1px 1px 3px 1px #9E9E9E",
                        borderRadius: 5,
                        paddingTop: '10px',
                        paddingBottom: '5px',
                        width: '100%'
                    }}>
                        <a href={"https://youtu.be/SG0DK107K-k"}
                           style={{
                               textDecorationLine: 'none',
                               textAlign: "center"
                           }}>
                            <Container>
                                <Row style={{display: 'flex', justifyContent: 'center'}}>
                                    <FiYoutube size={25} style={{marginBottom: 5}} color={'#32439e'}/>
                                </Row>
                                <Row style={{
                                    ...styles.normalText,
                                    fontWeight: '500',
                                    fontSize: '0.9em',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    color: '#333333'
                                }}>
                                    Youtube
                                </Row>
                            </Container>
                        </a>
                    </div>
                </Col>

                <Col md={4} xs={6} xl={3} style={{marginBottom: 20}}>
                    <div style={{
                        boxShadow: "1px 1px 3px 1px #9E9E9E",
                        borderRadius: 5,
                        paddingTop: '10px',
                        paddingBottom: '5px',
                        width: '100%'
                    }}>
                        <a href={"https://www.instagram.com/swissgermanuniv/"} onClick={(e) => {
                            e.preventDefault()
                        }} style={{
                            textDecorationLine: 'none',
                            textAlign: "center"
                        }}>
                            <Container>
                                <Row style={{display: 'flex', justifyContent: 'center'}}>
                                    <FiInstagram size={25} style={{marginBottom: 5}} color={'#32439e'}/>
                                    {/*<img*/}
                                    {/*    style={{*/}
                                    {/*        height: "2.5em",*/}
                                    {/*        objectFit: "contain",*/}
                                    {/*        marginBottom: '5px',*/}
                                    {/*        fontWeight: 'bold'*/}
                                    {/*    }}*/}
                                    {/*    src={require("../../asset/icc_pasar.png")}/>*/}
                                </Row>
                                <Row style={{
                                    ...styles.normalText,
                                    fontWeight: '500',
                                    fontSize: '0.9em',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    color: '#333333',
                                }}>
                                    Instagram
                                </Row>
                            </Container>
                        </a>
                    </div>
                </Col>

                <Col md={4} xs={6} xl={3} style={{marginBottom: 20}}>
                    <div
                        onClick={() => {
                            setCompetitionModal(true)
                        }}
                        style={{
                            boxShadow: "1px 1px 3px 1px #9E9E9E",
                            borderRadius: 5,
                            paddingTop: '10px',
                            paddingBottom: '5px',
                            width: '100%',
                            cursor: "pointer"
                        }}>
                        <Container>
                            <Row style={{display: 'flex', justifyContent: 'center'}}>
                                <IoMdTrophy size={25} style={{marginBottom: 5}} color={'#32439e'}/>
                                {/*<img*/}
                                {/*    style={{*/}
                                {/*        height: "2.5em",*/}
                                {/*        objectFit: "contain",*/}
                                {/*        marginBottom: '5px',*/}
                                {/*        fontWeight: 'bold'*/}
                                {/*    }}*/}
                                {/*    src={require("../../asset/icc_pasar.png")}/>*/}
                            </Row>
                            <Row style={{
                                ...styles.normalText,
                                fontWeight: '500',
                                fontSize: '0.9em',
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#333333',
                            }}>
                                Competition
                            </Row>
                        </Container>
                    </div>
                </Col>

                <Col md={4} xs={6} xl={3} style={{marginBottom: 20}}>
                    <div
                        onClick={() => {
                            setDoorPrizeModal(true)
                        }}
                        style={{
                            boxShadow: "1px 1px 3px 1px #9E9E9E",
                            borderRadius: 5,
                            paddingTop: '10px',
                            paddingBottom: '5px',
                            width: '100%',
                            cursor: "pointer"
                        }}>
                        <Container fluid>
                            <Row style={{display: 'flex', justifyContent: 'center'}}>
                                <GiPresent size={25} style={{marginBottom: 5}} color={'#32439e'}/>
                                {/*<img*/}
                                {/*    style={{*/}
                                {/*        height: "2.5em",*/}
                                {/*        objectFit: "contain",*/}
                                {/*        marginBottom: '5px',*/}
                                {/*        fontWeight: 'bold'*/}
                                {/*    }}*/}
                                {/*    src={require("../../asset/icc_pasar.png")}/>*/}
                            </Row>
                            <Row style={{
                                ...styles.normalText,
                                fontWeight: '500',
                                fontSize: '0.9em',
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#333333',
                            }}>
                                Doorprize Winners
                            </Row>
                        </Container>
                    </div>
                </Col>

            </Row>
        </Container>

        <Modal show={isRegisterShown} onHide={() => {
            setRegisterShown(false)
        }}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{...styles.boldText}}>Visitor Registration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{...styles.boldText}}>Email :</Form.Label>
                        <Form.Control type="email"
                                      onChange={(e) => {
                                          setRegisterCreds({
                                              ...registerCreds,
                                              email: e.target.value
                                          })
                                      }}
                                      isInvalid={!!errors.email}
                                      style={{...styles.normalText}}/>

                        <Form.Control.Feedback type="invalid" style={{...styles.normalText}}>
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{...styles.boldText}}>Phone Number :</Form.Label>
                        <Form.Control type="tel"
                                      onChange={(e) => {
                                          setRegisterCreds({
                                              ...registerCreds,
                                              mobile_phone: e.target.value
                                          })
                                      }}
                                      isInvalid={!!errors.mobile_phone}
                                      style={{...styles.normalText}}/>

                        <Form.Control.Feedback type="invalid" style={{...styles.normalText}}>
                            {errors.mobile_phone}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{...styles.boldText}}>Full Name : </Form.Label>
                        <Form.Control type="text"
                                      onChange={(e) => {
                                          setRegisterCreds({
                                              ...registerCreds,
                                              fullname: e.target.value
                                          })
                                      }}
                                      isInvalid={!!errors.fullname}
                                      style={{...styles.normalText}}/>

                        <Form.Control.Feedback type="invalid" style={{...styles.normalText}}>
                            {errors.fullname}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{...styles.boldText}}>Password : </Form.Label>
                        <Form.Control type="password"
                                      onChange={(e) => {
                                          setRegisterCreds({
                                              ...registerCreds,
                                              password: e.target.value
                                          })
                                      }}
                                      isInvalid={!!errors.password}
                                      style={{...styles.normalText}}/>

                        <Form.Control.Feedback type="invalid">
                            {errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{...styles.boldText}}>Password Confirmation : </Form.Label>
                        <Form.Control type="password"
                                      onChange={(e) => {
                                          setRegisterCreds({
                                              ...registerCreds,
                                              confirm_password: e.target.value
                                          })
                                      }}
                                      isInvalid={!!errors.confirm_password}
                                      style={{...styles.normalText}}/>

                        <Form.Control.Feedback type="invalid">
                            {errors.confirm_password}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{...styles.boldText}}>Gender : </Form.Label>
                        <Form.Select
                            onChange={(e) => {
                                setRegisterCreds({
                                    ...registerCreds,
                                    gender: e.target.value
                                })
                            }}
                            value={registerCreds.gender ? registerCreds.gender : 'Select Gender . . .'}
                            isInvalid={!!errors.gender}
                        >
                            <option disabled={true}>Select Gender . . .</option>
                            <option value={"M"}>Male</option>
                            <option value={"F"}>Female</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.gender}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{...styles.boldText}}>School : </Form.Label>
                        <Form.Control type="text"
                                      onChange={(e) => {
                                          setRegisterCreds({
                                              ...registerCreds,
                                              school_name: e.target.value
                                          })
                                      }}
                                      isInvalid={!!errors.school_name}
                                      style={{...styles.normalText}}/>

                        <Form.Control.Feedback type="invalid">
                            {errors.school_name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{...styles.boldText}}>Grade : </Form.Label>
                        <Form.Select onChange={(e) => {
                            setRegisterCreds({
                                ...registerCreds,
                                class: e.target.value
                            })
                        }}
                                     value={registerCreds.class ? registerCreds.class : 'Select Grade . . .'}
                                     isInvalid={!!errors.password}
                        >
                            <option disabled={true}>Select Grade . . .</option>
                            <option>Parent</option>
                            <option>9</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <option>Diploma 4</option>
                            <option>S1</option>
                            <option>Bachelor</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.class}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{...styles.boldText}}>Study Program Interest : </Form.Label>
                        <Form.Select
                            value={registerCreds.program ? registerCreds.program : 'Select Study Program Interest . . .'}
                            onChange={(e) => {
                                setRegisterCreds({
                                    ...registerCreds,
                                    program: e.target.value
                                })
                            }}
                            isInvalid={!!errors.program}
                        >
                            <option disabled={true}>Select Study Program Interest . . .</option>
                            <option>Accounting and Data Analytics</option>
                            <option>Biomedical Engineering</option>
                            <option>Business and Management</option>
                            <option>Food Technology</option>
                            <option>Global Strategic Communication</option>
                            <option>Hotel and Tourism Management</option>
                            <option>Industrial Engineering</option>
                            <option>Information Technology</option>
                            <option>International Culinary Business</option>
                            <option>Master of Arts in Business</option>
                            <option>Master of Business Administration</option>
                            <option>Master of Information Technology</option>
                            <option>Master of Mechanical Engineering</option>
                            <option>Mechatronics Engineering</option>
                            <option>Pharmaceutical Chemical Engineering</option>
                            <option>Sustainable Energy and Environment</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.program}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>

                <div style={{...styles.normalText}}>
                    Already have an account? <a href={'#'}
                                                onClick={() => {
                                                    setLoginShown(true)
                                                    setRegisterShown(false)
                                                }}>Login</a>
                </div>

                <div style={{...styles.normalText, marginTop: 15, color: 'red', marginBottom: 5}}>
                    {errors.form}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary"
                        style={{...styles.boldText}}
                        onClick={() => {
                            setRegisterShown(false)
                        }}>
                    Close
                </Button>
                <Button variant="primary"
                        disabled={isLoading}
                        style={{...styles.boldText, width: 86}}
                        onClick={attemptRegister}>
                    {
                        isLoading ?
                            <Spinner animation="border" size={'sm'}/> :
                            'Register'
                    }
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={isLoginShown} onHide={() => {
            setLoginShown(false)
        }}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{...styles.boldText}}>Visitor Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{...styles.boldText}}>Email :</Form.Label>
                        <Form.Control type="email"
                                      onChange={(e) => {
                                          setLoginCreds({
                                              ...loginCreds,
                                              email: e.target.value
                                          })
                                      }}
                                      isInvalid={!!errors.email}
                                      style={{...styles.normalText}}/>

                        <Form.Control.Feedback type="invalid" style={{...styles.normalText}}>
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{...styles.boldText}}>Password :</Form.Label>
                        <Form.Control type="password"
                                      onChange={(e) => {
                                          setLoginCreds({
                                              ...loginCreds,
                                              password: e.target.value
                                          })
                                      }}
                                      isInvalid={!!errors.password} style={{...styles.normalText}}/>

                        <Form.Control.Feedback type="invalid">
                            {errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>

                <div style={{...styles.normalText}}>
                    Don't have an account? <a href={'#'}
                                              onClick={() => {
                                                  setLoginShown(false)
                                                  setRegisterShown(true)
                                              }}>Sign up</a>
                </div>

                <div style={{...styles.normalText, marginTop: 15, color: 'red', marginBottom: 5}}>
                    {errors.form}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary"
                        style={{...styles.boldText}}
                        onClick={() => {
                            setLoginShown(false)
                        }}>
                    Close
                </Button>

                <Button variant="primary"
                        disabled={isLoading}
                        style={{...styles.boldText, width: 80}}
                        onClick={attemptLogin}>
                    {
                        isLoading ?
                            <Spinner animation="border" size={'sm'}/> :
                            'Login'
                    }
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={competitionModal} onHide={() => {
            setCompetitionModal(false)
        }}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{...styles.boldText}}></Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div>
                    <a href={"https://sgu-open-house.intellivent.id/eflyer/SEECOMP.pdf"} target={"_blank"}>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    SEE World Energy Day Competition
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </a>

                    <a href={"https://sgu-open-house.intellivent.id/eflyer/ACCCOMP.pdf"} target={"_blank"}>
                        <Card style={{
                            marginTop: 10
                        }}>
                            <Card.Body>
                                <Card.Text>
                                    ACC Accounting Mini Competition 2021
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </a>
                    <a href={"https://sgu-open-house.intellivent.id/eflyer/GSCCOMP.pdf"} target={"_blank"}>
                        <Card style={{
                            marginTop: 10
                        }}>
                            <Card.Body>
                                <Card.Text>
                                    GSC News Casting Competition
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </a>
                </div>

                <div style={{...styles.normalText, marginTop: 15, color: 'red', marginBottom: 5}}>
                    {errors.form}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary"
                        style={{...styles.boldText}}
                        onClick={() => {
                            setCompetitionModal(false)
                        }}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={doorPrizeModal} onHide={() => {
            setDoorPrizeModal(false)
        }}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{...styles.boldText}}></Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div>
                    Masing-masing pemenang mendapatkan hadiah voucher MAP senilai Rp. 100.000.<br/>
                    Pengundian doorprize dilakukan setiap tanggal 18, 20, 22, 25, 27 pukul 15.00 WIB bagi mereka yang login di hari pengundian.
                </div>

                <div style={{marginTop : 10}}>
                    <a href={"https://sgu-open-house.intellivent.id/winner_prize/doorprize18.mp4"} target={"_blank"}>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Pemenang Undian 18 Oktober 2021
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </a>
                </div>

                <div style={{marginTop : 10}}>
                    <a href={"https://sgu-open-house.intellivent.id/winner_prize/doorprize20.mp4"} target={"_blank"}>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Pemenang Undian 20 Oktober 2021
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </a>
                </div>

                <div style={{marginTop : 10}}>
                    <a href={"https://sgu-open-house.intellivent.id/winner_prize/doorprize22.mp4"} target={"_blank"}>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Pemenang Undian 22 Oktober 2021
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </a>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary"
                        style={{...styles.boldText}}
                        onClick={() => {
                            setCompetitionModal(false)
                        }}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
