import ApiRequest from "../utils/ApiRequest";

export default class Admin {
    login = async (body) => {
        return await ApiRequest.set(`/v1/user/login`, "POST", body);
    }

    register = async (body) => {
        return await ApiRequest.set(`/v1/user/register`, "POST", body);
    }
}
