import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import banner from "../assets/banner.jpeg";
import Modal from "react-bootstrap/Modal";
import {useHistory} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import React, {useEffect, useState} from "react";
import {
    BiFullscreen, BsPeopleFill,
    FaChalkboardTeacher, FaKeyboard, FaQuestion, FaThumbsUp, FaTrophy,
    FiInstagram,
    FiYoutube, GiArrowCursor, GrTrophy,
    HiOutlineDesktopComputer,
    IoDocumentTextOutline, RiSurveyLine
} from "react-icons/all";

import Unity, {UnityContext} from "react-unity-webgl";
import Header from "./Header";

import sguLogo from "../assets/favicon.png"
import ophBanner from "../assets/oph logo.png"
import sguWhite from "../assets/sgu-logo-white-300x139.png"
import Footer from "./Footer";
import Palette from "../utils/Palette";

import {isSafari, isOpera, isMobile} from "react-device-detect";
import Swal from "sweetalert2";
import {Popover} from "bootstrap";
import Tooltip from "react-bootstrap/cjs/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const unityContext = new UnityContext({
    loaderUrl: "build/Build.loader.js",
    dataUrl: "build/Build.data.unityweb",
    frameworkUrl: "build/Build.framework.js.unityweb",
    codeUrl: "build/Build.wasm.unityweb",
});


unityContext.on("error", function (message) {
    alert("An error occured. Please try refreshing the page or use another browser.")
});

const TUTORIAL_NODES = [
    {
        title: "Welcome",
        content: "Welcome to Swiss German University Virtual Open House!",
        icon: <img
            style={{
                marginTop: 15,
                height: "5em",
                objectFit: "contain"
            }}
            src={sguWhite}/>
    },
    {
        title: "Controls",
        content: "Use the WASD keys to move your avatar around the virtual exhibition area.",
        icon: <FaKeyboard
            style={{
                marginTop: 15
            }}
            size={"5em"}/>
    },
    {
        title: "Interact",
        content: "By pressing the screen, you can interact with different object in the exhibition area.",
        icon: <GiArrowCursor
            style={{
                marginTop: 30
            }}
            size={"5em"}/>
    },
    {
        title: "Meet",
        content: "You can meet your friends participating in the virtual room!",
        icon: <BsPeopleFill
            style={{
                marginTop: 30
            }}
            size={"5em"}/>
    },
    {
        title: "Games",
        content: "Interact with all the booths to get points. There will be a daily reward for winners from 18 October - 27 October 2021.",
        icon: <FaTrophy
            style={{
                marginTop: 30
            }}
            size={"5em"}/>
    },
]

export default function LandingPage(props) {
    const history = useHistory();
    const [isLoginShown, setLoginShown] = useState(false)
    const [isRegisterShown, setRegisterShown] = useState(false)

    const [progression, setProgression] = useState(0)
    const [tutorialModal, setTutorialModal] = useState(false)

    const [tutorialPage, setTutorialPage] = useState(0)

    const [isPopperShown, setIsPopperShown] = useState(true);

    useEffect(function () {

        if (!localStorage.getItem('token') || !localStorage.getItem('gender') || !localStorage.getItem('fullname')) {
            localStorage.setItem('token',"demo")
            localStorage.setItem('gender', "M")
            localStorage.setItem('fullname', "Yulius")
        }

        // if (!localStorage.getItem('token')) {
        //     history.push('/login')
        // } else {
            unityContext.on("progress", function (progression) {
                setProgression(progression);
            });
        // }

        if (!localStorage.getItem('first_visit')) {
            localStorage.setItem('first_visit', "true")
            setTutorialModal(true)
        }

        if (isSafari || isMobile || isOpera) {
            Swal.fire({
                title: 'This browser may not support the virtual expo',
                text: 'For the best experience, please consider using the desktop version of Google Chrome or Microsoft Edge.',
                icon: 'info',
                confirmButtonText: 'OK'
            }).then((result) => {
                setLoginShown(true)
            })
        }

    }, []);


    return <>
        <Modal
            show={tutorialModal}
            size={"xs"}>

            <Modal.Body style={{
                backgroundColor: "#2e4d9e",
                height: 350,
                color: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
            >
                <div style={{
                    fontSize: "1.5em"
                }}>
                    {TUTORIAL_NODES[tutorialPage].title}
                </div>


                {TUTORIAL_NODES[tutorialPage].icon}


                <div style={{
                    width: "80%",
                    textAlign: "center",
                    marginTop: 30,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    {TUTORIAL_NODES[tutorialPage].content}
                </div>

                <div style={{
                    marginTop: 20,
                    width: "80%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                }}>
                    {
                        TUTORIAL_NODES.map((obj, index) => {
                            return <div style={{
                                width: 10,
                                height: 10,
                                borderRadius: 20,
                                marginLeft: 5,
                                marginRight: 5,
                                backgroundColor: tutorialPage === index ? "white" : Palette.SGU_LIGHT_BLUE
                            }}/>
                        })
                    }

                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 30,
                    width: "100%"
                }}>
                    {
                        tutorialPage !== 0 &&
                        <div
                            onClick={() => {
                                setTutorialPage(tutorialPage - 1)
                            }}
                            style={{
                                cursor: "pointer"
                            }}
                        >
                            Previous
                        </div>
                    }


                    <div style={{
                        flex: 1,
                        width: "100%",
                        height: 10,
                    }}>

                    </div>

                    {
                        tutorialPage === TUTORIAL_NODES.length - 1 ?
                            <div
                                onClick={() => {
                                    setTutorialModal(false)
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                            >
                                Finish
                            </div>
                            :
                            <div
                                onClick={() => {
                                    setTutorialPage(tutorialPage + 1)
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                            >
                                Next
                            </div>
                    }


                </div>

            </Modal.Body>

        </Modal>
        <Container
            style={{
                padding: 0
            }}
            fluid>
            <Header
                entranceTestButton
                loginButton={false}
                openLogin={() => setLoginShown(true)}/>
        </Container>
        <Container>

            <Row style={{
                marginTop: 85,
                display: "flex",
                justifyContent: "center"
            }}

            >
                <Col
                    style={{
                        display: "flex",
                        flexDirection: "column"

                    }}
                    md={8}>
                    {
                        progression < 1 &&
                        <div style={{
                            height: 600,
                            width: "100%",
                            backgroundColor: "#2e4d9e",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>

                            <img
                                style={{
                                    width: 100,
                                    height: 100
                                }}
                                src={sguLogo}/>

                            <img
                                style={{
                                    width: "80%",
                                    height: 100,
                                    objectFit: "contain",
                                    marginTop: 10
                                }}
                                src={ophBanner}/>

                            <div style={{
                                marginTop: 30,
                                width: 200,
                                backgroundColor: "white",
                                height: 15
                            }}>
                                <div style={{
                                    width: progression * 100 + "%",
                                    backgroundColor: "#00adef",
                                    height: 15
                                }}>

                                </div>
                            </div>

                            <div style={{
                                marginTop: 10,
                                color: "white"
                            }}>
                                Loading the Virtual Exhibition
                            </div>

                        </div>
                    }

                    <Unity

                        style={{
                            height: progression >= 1 ? 600 : 0,
                            width: "100%",
                        }}
                        unityContext={unityContext}/>

                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row-reverse",
                        marginTop: 10
                    }}>
                        <div style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "flex-end"
                        }}>
                            {
                                progression >= 1 &&
                                <Button
                                    style={{
                                        height: "2em",
                                        width: "2em",
                                        display: "flex",
                                        alignItems: 'center',
                                        justifyContent: "center",
                                        padding: 0
                                    }}
                                    onClick={() => {
                                        unityContext.setFullscreen(true);
                                    }}
                                >
                                    <BiFullscreen/>
                                </Button>
                            }

                        </div>

                        <div style={{
                            fontSize : 12,
                            marginLeft : 5
                        }}>
                            Silahkan klik dan lengkapi link feedback disamping untuk mendapatkan<br/>
                            souvenir menarik yang akan kami kirimkan ke alamat Anda!
                        </div>

                        <a target={"_blank"} href={"https://forms.gle/BMc3pqbacZgJJgCH7"}>
                            {/*<OverlayTrigger*/}
                            {/*    show={isPopperShown}*/}
                            {/*    placement="right"*/}
                            {/*    delay={{show: 250, hide: 400}}*/}
                            {/*    overlay={<Tooltip*/}
                            {/*        onClick={() => setIsPopperShown(false)}*/}
                            {/*        id="button-tooltip" {...props}>*/}
                            {/*        Silahkan klik dan lengkapi link feedback disamping untuk mendapatkan souvenir*/}
                            {/*        menarik yang akan kami kirimkan ke alamat Anda!*/}
                            {/*    </Tooltip>}*/}
                            {/*>*/}
                                <Button
                                    onClick={()=>{
                                        setIsPopperShown(false)
                                    }}
                                    style={{
                                        height: "2em",
                                        width: "2em",
                                        display: "flex",
                                        alignItems: 'center',
                                        justifyContent: "center",
                                        padding: 0
                                    }}
                                >
                                    <RiSurveyLine/>
                                </Button>
                        {/*    </OverlayTrigger>*/}
                        </a>

                        <Button
                            style={{
                                marginRight: 5,
                                height: "2em",
                                width: "2em",
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: "center",
                                padding: 0
                            }}
                            onClick={() => {
                                setTutorialPage(0)
                                setTutorialModal(true)
                            }}
                        >
                            <FaQuestion/>
                        </Button>

                    </div>

                </Col>
            </Row>
        </Container>
        <Container
            style={{padding: 0, marginTop: 50}}
            fluid>
            <Footer/>
        </Container>
    </>
}
