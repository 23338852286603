import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {FaKeyboard, FaMapMarkerAlt} from "react-icons/all";
import {FaEnvelope, FaInstagram, FaWhatsapp, FaYoutube} from "react-icons/all";

import faqPDF from "../assets/FAQ SGU VIRTUAL TOUR .pdf"
import sguWhite from "../assets/sgu-logo-white-300x139.png"
import Modal from "react-bootstrap/Modal";
import Palette from "../utils/Palette";
import lsdLogo from "../assets/VLSD LOGO.png"

export default function Footer(props) {

    const [aboutUsModal, setAboutUsModal] = useState(false)


    return <>

        <Modal
            show={aboutUsModal}
            size={"xs"}>

            <Modal.Body style={{
                backgroundColor: "#2e4d9e",
                height: 350,
                color: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "center",
                padding: 20
            }}
            >

                <b>About Virtual Expo</b>

                <br/>
                Powered By:<b style={{fontFamily: "Spartan", fontWeight: "900", marginTop: 10}}>INTELLIVENT</b>
                <br/>
                Booth and Hall Design By :

                <a href={"https://www.instagram.com/Rayagyra/"}>
                    <img
                        style={{
                            height : 85,
                            marginTop : 15,
                            objectFit : "contain"
                        }}
                        src={lsdLogo}/>

                </a>


                {/*<b style={{fontWeight: "900", marginTop: 10}}>Ray Agra</b>*/}
                <br/>
                {/*<img*/}
                {/*    style={{*/}
                {/*        height: 60,*/}
                {/*        objectFit: "contain"*/}
                {/*    }}*/}
                {/*    src={sguWhite}/>*/}

                <div
                    onClick={()=>{
                        setAboutUsModal(false)
                    }}
                    style={{
                    cursor : "pointer",
                    marginTop : 10,
                    display:"flex",
                    justifyContent : "flex-end",
                    width :"100%"
                }}>
                    Close
                </div>


            </Modal.Body>

        </Modal>

        <div style={{winWidth: '100vh', backgroundColor: '#dedede', height: 1}}/>
        <div style={{
            // height: "15em",
            width: "100%",
            // boxShadow: "5px 5px 5px 5px #888888",
        }}>
            <Container style={{
                width: "100%",
                zIndex: 1000,
                display: "flex",
                background: "white",
                color: "black",
                fontSize: "0.9em",
                paddingTop: "1em"
            }}>
                <Row style={{marginTop: "0.5em", marginBottom: "1em", width: "100%"}}>
                    <Col md={3} style={{display: "flex", flexDirection: "column"}}>
                        <b style={{marginTop: "0.5em"}}>
                            Hubungi Kami
                        </b>
                        <ul style={{paddingLeft: "0.1em", marginTop: "1em"}}>
                            <FaMapMarkerAlt style={{color: "#2e4d9e", marginRight: "0.5em"}}/>
                            The Prominence Tower Alam Sutera,<br/>
                            Jl. Jalur Sutera Barat. No. 15, RT.003/RW.006, <br/>
                            Panunggangan Tim., Kec. Pinang, <br/>
                            Kota Tangerang, <br/>
                            Banten 15143
                        </ul>

                    </Col>
                    <Col md={6} style={{display: "flex", flexDirection: "column"}}>

                        <b style={{marginTop: "0.5em"}}>
                            Hubungi Kami
                        </b>

                        <a target="_blank">
                            <ul
                                style={{
                                    paddingLeft: "0.1em", marginTop: "1em",
                                    cursor: "pointer"
                                }}
                            >
                                <FaWhatsapp style={{color: "#2e4d9e", marginRight: "0.5em", fontSize: "1.2em"}}/>
                                <a href={"https://wa.me/+628118010600"} target="_blank">
                                    +628118010600
                                </a>
                                /
                                <a href={"https://wa.me/+628119958010"} target="_blank">
                                    +628119958010
                                </a>
                            </ul>
                        </a>

                        <a href={"https://www.instagram.com/swissgermanuniv/"} target="_blank">
                            <ul style={{paddingLeft: "0.1em", marginTop: ".1em"}}>
                                <FaInstagram style={{color: "#2e4d9e", marginRight: "0.5em"}}/>
                                Instagram
                            </ul>
                        </a>

                        <a href={"https://youtu.be/SG0DK107K-k"} target="_blank">
                            <ul style={{paddingLeft: "0.1em", marginTop: ".1em"}}>
                                <FaYoutube style={{color: "#2e4d9e", marginRight: "0.5em"}}/>
                                YouTube
                            </ul>
                        </a>

                    </Col>
                    <Col md={3} style={{display: "flex", flexDirection: "column"}}>

                        <b style={{marginTop: "0.5em"}}>
                            Lainnya
                        </b>


                        <a style={{marginTop: "1em"}} href={"https://www.sgu.ac.id"} target={"_blank"}>
                            Situs Utama
                        </a>

                        <a href={faqPDF} download>
                            FAQ
                        </a>

                        <a href="" onClick={(e) => {
                            e.preventDefault()
                            setAboutUsModal(true)
                        }}>
                            Tentang SGU Virtual Expo
                        </a>

                    </Col>
                    {/*<Col md={12} style={{textAlign: "center", paddingTop: "1em"}}>*/}
                    {/*    Powered By*/}
                    {/*    <b style={{fontFamily: "Spartan", fontWeight: "900"}}>&nbsp;INTELLIVENT</b>*/}
                    {/*</Col>*/}
                </Row>
            </Container>

        </div>
    </>
}
