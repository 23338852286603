import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useState} from "react";
import Header from "./components/Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import banner from "./assets/banner.jpeg"
import Card from "react-bootstrap/Card";
import {FaUserGraduate} from "react-icons/all";
import styles from "./values/styles";
import {FiFacebook, FiInstagram, FiTwitter, FiYoutube} from "react-icons/fi";
import {IoDocumentTextOutline} from "react-icons/io5";
import {FaChalkboardTeacher, FaWhatsapp} from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {HiOutlineDesktopComputer} from "react-icons/hi";
import {RiWhatsappLine} from "react-icons/ri";
import Collapse from "@material-ui/core/Collapse/Collapse";
import {
    isMobile
} from "react-device-detect";

import {BrowserRouter, HashRouter, Route, Switch} from "react-router-dom"
import LandingPage from "./components/LandingPage";
import UnityPage from "./components/UnityPage";


function App() {
    const [isLoginShown, setLoginShown] = useState(false)
    const [isRegisterShown, setRegisterShown] = useState(false)
    const [isWAContactExpanded, setWAContactExpanded] = useState(false);
    return (
        <Container fluid style={{
            padding: 0,
            overflowX : "hidden"
        }}>

            <Collapse in={isWAContactExpanded}>
                <div style={{
                    position: "fixed",
                    bottom: isMobile ? "calc(4em + 70px)" : "120px",
                    right: "25px",
                    display: "flex",
                    flexDirection: "column",
                }}
                >
                    <a href={"https://wa.me/+628118010600"} target="_blank" style={{
                        ...styles.boldText,
                        textDecorationLine: 'none',
                        color: '#333333'
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            marginTop: isMobile ? "15px" : "25px"
                        }}>
                            {/*<div style={{*/}
                            {/*    color: "#25D366"*/}
                            {/*}}>*/}
                            {/*    <b>Whatsapp&nbsp;</b>*/}
                            {/*</div>*/}
                            <div style={{
                                height: isMobile ? '55px' : '55px',
                                width: isMobile ? '200px' : "200px",
                                // width: isMobile ? '55px' : "70px",
                                borderRadius: "55px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                cursor: "pointer",
                                zIndex: "9999999",
                                paddingLeft: "22px",
                                backgroundColor: "white"
                            }}
                            >
                                MAD Bachelor
                            </div>
                        </div>

                    </a>

                    <a href={"https://wa.me/+628119958010"} target="_blank" style={{
                        ...styles.boldText,
                        textDecorationLine: 'none',
                        color: '#333333'
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            marginTop: isMobile ? "15px" : "25px"
                        }}>
                            <div style={{
                                backgroundColor: "white",
                                height: isMobile ? '55px' : '55px',
                                // width: isMobile ? '55px' : "70px",
                                width: isMobile ? '200px' : "200px",
                                borderRadius: "55px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                cursor: "pointer",
                                zIndex: "9999999",
                                paddingLeft: "22px",
                            }}
                            >
                                MAD Master
                            </div>
                        </div>
                    </a>
                </div>
            </Collapse>

            <div
                onClick={() => setWAContactExpanded(!isWAContactExpanded)}
                style={{
                    cursor: 'pointer',
                    position: 'fixed',
                    zIndex: 9999999,
                    bottom: 25,
                    right: 25,
                    backgroundColor: '#26d367',
                    width: 70,
                    height: 70,
                    borderRadius: 35,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: 'rgb(136, 136, 136) 0px 0px 3px 1px',
                }}>
                <RiWhatsappLine color={'white'} size={35}/>
            </div>

            <Modal show={isRegisterShown} onHide={() => setRegisterShown(false)}>
                <Modal.Header closeButton>
                    <Modal.Title style={{...styles.boldText}}>Visitor Registration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{...styles.boldText}}>Email :</Form.Label>
                            <Form.Control type="email" style={{...styles.normalText}}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{...styles.boldText}}>Phone Number :</Form.Label>
                            <Form.Control type="tel" style={{...styles.normalText}}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label style={{...styles.boldText}}>Full Name : </Form.Label>
                            <Form.Control type="text" style={{...styles.normalText}}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label style={{...styles.boldText}}>Password : </Form.Label>
                            <Form.Control type="password" style={{...styles.normalText}}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label style={{...styles.boldText}}>Password Confirmation : </Form.Label>
                            <Form.Control type="password" style={{...styles.normalText}}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label style={{...styles.boldText}}>School : </Form.Label>
                            <Form.Control type="text" style={{...styles.normalText}}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label style={{...styles.boldText}}>Grade : </Form.Label>
                            <Form.Select>
                                <option disabled={true}>Select Grade . . .</option>
                                <option>Parent</option>
                                <option>9</option>
                                <option>10</option>
                                <option>11</option>
                                <option>12</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label style={{...styles.boldText}}>Study Program Interest : </Form.Label>
                            <Form.Select>
                                <option disabled={true}>Select Study Program Interest . . .</option>
                                <option>Accounting and Data Analytics</option>
                                <option>Biomedical Engineering</option>
                                <option>Business and Management</option>
                                <option>Food Technology</option>
                                <option>Global Strategic Communication</option>
                                <option>Hotel and Tourism Management</option>
                                <option>Industrial Engineering</option>
                                <option>Information Technology</option>
                                <option>International Culinary Business</option>
                                <option>Master of Arts in Business</option>
                                <option>Master of Business Administration</option>
                                <option>Master of Information Technology</option>
                                <option>Master of Mechanical Engineering</option>
                                <option>Mechatronics Engineering</option>
                                <option>Pharmaceutical Chemical Engineering</option>
                                <option>Sustainable Energy and Environment</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>

                    <div style={{...styles.normalText}}>
                        Already have an account? <a href={'#'}
                                                    onClick={() => {
                                                        setLoginShown(true)
                                                        setRegisterShown(false)
                                                    }}>Login</a>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                            style={{...styles.boldText}}
                            onClick={() => setRegisterShown(false)}>
                        Close
                    </Button>
                    <Button variant="primary"
                            style={{...styles.boldText}}
                            onClick={() => {
                            }}>
                        Login
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isLoginShown} onHide={() => setLoginShown(false)}>
                <Modal.Header closeButton>
                    <Modal.Title style={{...styles.boldText}}>Visitor Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{...styles.boldText}}>Email :</Form.Label>
                            <Form.Control type="tel" style={{...styles.normalText}}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label style={{...styles.boldText}}>Password :</Form.Label>
                            <Form.Control type="password" style={{...styles.normalText}}/>
                        </Form.Group>
                    </Form>

                    <div style={{...styles.normalText}}>
                        Don't have an account? <a href={'#'}
                                                  onClick={() => {
                                                      setLoginShown(false)
                                                      setRegisterShown(true)
                                                  }}>Sign up</a>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                            style={{...styles.boldText}}
                            onClick={() => setLoginShown(false)}>
                        Close
                    </Button>

                    <a href={'https://expodemo.intellivent.id/sgu-open-house'}>
                        <Button variant="primary"
                                style={{...styles.boldText}}
                                onClick={() => {
                                }}>
                            Login
                        </Button>
                    </a>
                </Modal.Footer>
            </Modal>

            <HashRouter>

                {/*<BrowserRouter>*/}
                <Switch>
                    <Route exact path={"/"} component={LandingPage}/>
                    <Route exact path={"/login"} component={LandingPage}/>
                    <Route exact path={"/expo"} component={UnityPage}/>
                </Switch>
                {/*</BrowserRouter>*/}
            </HashRouter>


        </Container>
    );
}

export default App;
