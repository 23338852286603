import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../assets/logo.jpg"
import Button from "react-bootstrap/Button";
import styles from "../values/styles";
import {Link} from "react-router-dom";

function Header(props) {
    const {joinExpo, loginButton, entranceTestButton} = props

    return (
        <Container fluid style={{
            position: 'fixed', top: 0, zIndex: 2,
            backgroundColor: "red",
            width: "100%"
        }}>
            <Row style={{
                boxShadow: 'rgb(136, 136, 136) 0px -2px 3px 1px',
                // display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
                backgroundColor: 'white',
                height: 70,
                padding: 5
            }}>
                <Col xs={12} style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <a
                        style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center"
                        }}
                        href={"/"}>

                        <img
                            src={logo}
                            style={{
                                height: 60,
                                flex: 1,
                                marginLeft: (loginButton || entranceTestButton) ? 100 : 0,
                                objectFit: "contain"
                            }}/>

                    </a>

                    {
                        loginButton &&
                        <Button
                            style={{
                                ...styles.boldText,
                                width: 100,
                            }}
                            onClick={joinExpo}
                            size={"sm"}>
                            Join Expo
                        </Button>
                    }
                    {
                        entranceTestButton &&
                            <a href={"https://entrancetest.sgu.ac.id/"}>
                                <Button
                                    style={{
                                        ...styles.boldText,
                                        width: 120,
                                    }}
                                    size={"sm"}>
                                    Entrance Exam
                                </Button>
                            </a>

                    }

                </Col>
            </Row>
        </Container>
    );
}

export default Header;
